import { Spinner } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import { File, FileText, Folder, List, Tool, Users } from 'react-feather';

import { useDashboardAnalytics } from '../api/getAnalytics';

export const Dashboard = () => {
  const analyticsQuery = useDashboardAnalytics();

  if (analyticsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!analyticsQuery.data) return null;

  return (
    <ContentLayout title="Dashboard">
      <div className="grid col-auto lg:grid-cols-3 gap-4 mt-6">
        <div className="bg-white p-6 border border-gray-200 shadow-md rounded">
          <div className="flex items-center justify-between">
            <div>
              <h1 className="text-blue-600 text-sm font-bold">SERVICES</h1>
              <h1 className="font-bold text-2xl text-gray-700">{analyticsQuery.data.services}</h1>
            </div>
            <div>
              <Tool className="h-10 w-10 text-gray-300" />
            </div>
          </div>
        </div>

        <div className="bg-white p-6 border border-gray-200 shadow-md rounded">
          <div className="flex items-center justify-between">
            <div>
              <h1 className="text-blue-600 text-sm font-bold">USERS</h1>
              <h1 className="font-bold text-2xl text-gray-700">{analyticsQuery.data.users}</h1>
            </div>
            <div>
              <Users className="h-10 w-10 text-gray-300" />
            </div>
          </div>
        </div>

        <div className="bg-white p-6 border border-gray-200 shadow-md rounded">
          <div className="flex items-center justify-between">
            <div>
              <h1 className="text-blue-600 text-sm font-bold">RESOURCES</h1>
              <h1 className="font-bold text-2xl text-gray-700">{analyticsQuery.data.resources}</h1>
            </div>
            <div>
              <File className="h-10 w-10 text-gray-300" />
            </div>
          </div>
        </div>

        <div className="bg-white p-6 border border-gray-200 shadow-md rounded">
          <div className="flex items-center justify-between">
            <div>
              <h1 className="text-blue-600 text-sm font-bold">CAREERS</h1>
              <h1 className="font-bold text-2xl text-gray-700">{analyticsQuery.data.jobs}</h1>
            </div>
            <div>
              <Folder className="h-10 w-10 text-gray-300" />
            </div>
          </div>
        </div>

        <div className="bg-white p-6 border border-gray-200 shadow-md rounded">
          <div className="flex items-center justify-between">
            <div>
              <h1 className="text-blue-600 text-sm font-bold">DEPARTMENTS</h1>
              <h1 className="font-bold text-2xl text-gray-700">
                {analyticsQuery.data.departments}
              </h1>
            </div>
            <div>
              <List className="h-10 w-10 text-gray-300" />
            </div>
          </div>
        </div>

        <div className="bg-white p-6 border border-gray-200 shadow-md rounded">
          <div className="flex items-center justify-between">
            <div>
              <h1 className="text-blue-600 text-sm font-bold">POSTS</h1>
              <h1 className="font-bold text-2xl text-gray-700">{analyticsQuery.data.posts}</h1>
            </div>
            <div>
              <FileText className="h-10 w-10 text-gray-300" />
            </div>
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};
