import { Spinner } from '@/components/Elements';
import { MainLayout } from '@/components/Layout';
import { lazyImport } from '@/utils/lazyImport';
import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const { ResourcesRoutes } = lazyImport(() => import('@/features/resources'), 'ResourcesRoutes');
const { MessagesRoutes } = lazyImport(() => import('@/features/messages'), 'MessagesRoutes');
const { PostsRoutes } = lazyImport(() => import('@/features/blog'), 'PostsRoutes');
const { JobsRoutes } = lazyImport(() => import('@/features/careers'), 'JobsRoutes');
const { DepartmentsRoutes } = lazyImport(
  () => import('@/features/departments'),
  'DepartmentsRoutes'
);
const { AdsRoutes } = lazyImport(() => import('@/features/ads'), 'AdsRoutes');
const { ManagersRoutes } = lazyImport(() => import('@/features/management'), 'ManagersRoutes');
const { ServicesRoutes } = lazyImport(() => import('@/features/services'), 'ServicesRoutes');
const { ComingSoon } = lazyImport(() => import('@/features/comingsoon'), 'ComingSoon');
const { Settings } = lazyImport(() => import('@/features/settings'), 'Settings');
const { Dashboard } = lazyImport(() => import('@/features/misc'), 'Dashboard');
const { Profile } = lazyImport(() => import('@/features/users'), 'Profile');
const { Users } = lazyImport(() => import('@/features/users'), 'Users');

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: '/app',
    element: <App />,
    children: [
      { path: '/career/*', element: <JobsRoutes /> },
      { path: '/blog/*', element: <PostsRoutes /> },
      { path: '/departments/*', element: <DepartmentsRoutes /> },
      { path: '/services/*', element: <ServicesRoutes /> },
      { path: '/messages/*', element: <MessagesRoutes /> },
      { path: '/resources/*', element: <ResourcesRoutes /> },
      { path: '/management/*', element: <ManagersRoutes /> },
      { path: '/ads/*', element: <AdsRoutes /> },
      { path: '/users', element: <Users /> },
      { path: '/settings', element: <Settings /> },
      { path: '/profile', element: <Profile /> },
      { path: '/coming-soon', element: <ComingSoon /> },
      { path: '/', element: <Dashboard /> },
      { path: '*', element: <Navigate to="." /> },
    ],
  },
];
