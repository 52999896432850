import { axios } from '@/lib/axios';
import { useQuery } from 'react-query';

export const getDashboardAnalytics = () => {
  return axios.get('/analytics/dashboard');
};

export const useDashboardAnalytics = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['dashboardAnalytics'],
    queryFn: () => getDashboardAnalytics(),
  });
};
