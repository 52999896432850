import clsx from 'clsx';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import { FieldWrapper } from './FieldWrapper';

export const TextAreaField = (props) => {
  const { label, className, value, placeholder, error, setValue, editorContent } = props;
  const onEditorStateChange = (editorState) => {
    setValue(value, editorState);
  };

  return (
    <FieldWrapper label={label} error={error}>
      <ReactQuill
        theme="snow"
        placeholder={placeholder}
        className={clsx(
          'appearance-none block w-full border-none placeholder-gray-400 focus:outline-none sm:text-sm',
          className
        )}
        value={editorContent}
        onChange={onEditorStateChange}
      />
    </FieldWrapper>
  );
};
