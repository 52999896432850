import React from 'react';
import { Image } from 'react-feather';

export const UploadField = (props) => {
  const { title, subTitle, x, y, onChange, preview = '' } = props;

  return (
    <>
      <h1 className="text-center font-bold text-lg">{title}</h1>
      <div className="text-center">
        <span className="text-gray-500 text-sm">{subTitle}</span>
      </div>
      <>
        {preview ? (
          <>
            <div className="border border-dotted rounded p-2 flex flex-col items-center justify-center">
              <img src={preview} className="w-100" alt="uploaded to server" />
            </div>
          </>
        ) : (
          <div className="border border-dotted rounded p-10 flex flex-col items-center justify-center mt-4">
            <Image className="text-gray-300 h-20 w-20" />
            <span className="text-gray-500 text-sm">
              {x} x {y} or higher recommended.
            </span>
            <label className="">
              <span className="mt-2 text-base leading-normal">
                <span className="text-red-500 cursor-pointer">Browse</span> for image.
              </span>
              <input
                type="file"
                accept="image/*"
                name="file"
                className="hidden"
                onChange={onChange}
              />
            </label>
          </div>
        )}
      </>
    </>
  );
};
