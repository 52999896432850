import PropTypes from 'prop-types';
import * as React from 'react';

import { Head } from '../Head';

export const ContentLayout = ({ children, title, button }) => {
  return (
    <>
      <Head title={title} />
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="flex flex-row justify-between">
            <h1 className="text-2xl font-semibold text-gray-900 dark:text-gray-400">{title}</h1>
            <div>{button}</div>
          </div>
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">{children}</div>
        {/* <div>
          © Copyright 2021 - National Curriculum Development Centre © 2022, Made by GreyPoint Media
        </div> */}
      </div>
    </>
  );
};

ContentLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};
